import { Fragment, useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { BreadcrumbProps } from '@molecules/Breadcrumb/Breadcrumb.props';

import theme from '@mui/theme';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useBreadcrumbSegments } from '@hooks/useBreadcrumbSegments';
import { useBreakpoint } from '@hooks/useBreakpoint';
import removeHyphens from '@utils/functions/remove-hyphens';
import useDeviceDetect from '@utils/functions/deviceDetect';

const useIosDetection = () => {
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    if (!window || !document) return;
    const userAgent = navigator?.userAgent;
    if (!userAgent) return;
    const isMobile =
      ['iPhone', 'iPad', 'iPod'].some((d) => userAgent.includes(d)) ||
      (navigator?.userAgent.match(/Mac/) && !!navigator?.maxTouchPoints);
    setIsIos(isMobile);
  }, []);
  return { isIos };
};

const Breadcrumb = (props: BreadcrumbProps) => {
  const { colorVariant, node } = props;

  const segments = useBreadcrumbSegments(node);

  const router = useRouter();

  const { t } = useTranslation();

  const { isSmaller: isMobile } = useBreakpoint('lg');
  const { isIos } = useIosDetection();

  if (node.path?.alias === '/homepage') return <></>;

  return (
    <Box
      display="inline-flex"
      mt={theme.spacing(40)}
      borderRadius={theme.spacing(16)}
      alignItems="center"
      boxShadow={
        colorVariant === 'light'
          ? 'inset 0px -12px 10px rgba(0, 0, 0, 0.03)'
          : 'inset 0px -12px 10px rgba(0, 0, 0, 0.03)'
      }
      sx={{
        background:
          colorVariant === 'light'
            ? 'linear-gradient(360deg, rgba(217, 226, 228, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%)'
            : 'linear-gradient(360deg, rgba(217, 226, 228, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%)',
        padding: { xs: theme.spacing(10, 24), lg: theme.spacing(11.5, 24) },
        position: 'relative',
        zIndex: 10,
      }}
    >
      {(isMobile || isIos) && (
        <Link
          color={colorVariant === 'light' ? 'grey.900' : 'common.white'}
          display="flex"
          href="#"
          sx={{ textDecoration: 'none' }}
          onClick={() => router.back()}
          gap={theme.spacing(8)}
          alignItems="center"
        >
          <Icon
            style={{ fontSize: theme.spacing(24) }}
            sx={{ color: colorVariant === 'light' ? 'primary.700' : 'common.white' }}
          >
            west
          </Icon>

          <Typography fontSize={theme.spacing(16)} lineHeight={theme.spacing(16)}>
            {t('header.goBack')}
          </Typography>
        </Link>
      )}

      {!(isMobile || isIos) &&
        segments.map((segment, index) => (
          <Fragment key={index}>
            {!segment.isActive && (
              <Box display="flex" alignItems="center">
                <NextLink prefetch={false} passHref legacyBehavior href={segment.url}>
                  <Link
                    color={colorVariant === 'light' ? 'primary.main' : 'common.white'}
                    sx={{ textDecoration: colorVariant === 'light' ? 'none' : 'underline' }}
                  >
                    <Typography variant="bodyS">{removeHyphens(segment.label)}</Typography>
                  </Link>
                </NextLink>

                <Icon style={{ fontSize: theme.spacing(16) }} sx={{ color: 'grey.500' }}>
                  chevron_right
                </Icon>
              </Box>
            )}

            {segment.isActive && (
              <Typography variant="bodyS" color="grey.500">
                {removeHyphens(segment.label)}
              </Typography>
            )}
          </Fragment>
        ))}
    </Box>
  );
};

export default Breadcrumb;
