import Cookies from 'js-cookie';

export const getCookie = (name) => {
  const cookieValue = Cookies.get(name);
  return cookieValue ? JSON.parse(cookieValue) : [];
};

export const setCookie = (name, value, days = 7) => {
  Cookies.set(name, JSON.stringify(value), { expires: days, path: '/' });
};

export const saveNidInCookie = (nid) => {
  const cookieName = 'discovery_video_nids';
  const existingNids = getCookie(cookieName);

  if (!existingNids.includes(nid)) {
    existingNids.push(nid);
    setCookie(cookieName, existingNids);
  }
};
