import { useEffect, useState } from 'react';
import { DrupalNode } from 'next-drupal';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { CUSTOM_IDENTIFIER_CONSTANT } from '@utils/constants/constants';
import { getWBCookiesMail } from '@utils/functions/getWBCookiesMail';
import { getCookiesHASH } from '@utils/functions/getCookiesHASH';

export default function useCookieForm(node: DrupalNode) {
  const cookieFormTitle = node?.title || '';
  const formCookieConsent = Cookies.get('form_cookie_consent');
  const [showForm, setShowForm] = useState(false);
  const [cookieDomain, setCookieDomain] = useState('');

  const { query } = useRouter();

  useEffect(() => {
    const currentDomain = window.location.hostname;
    setCookieDomain(currentDomain.replace(/^www\./, ''));
  }, []);

  useEffect(() => {
    const cleanUp = () => setShowForm(false);
    const isRequiredPersonalInfoPage = node?.type === 'node--page' && !!node?.field_require_personalinfo;

    if (!isRequiredPersonalInfoPage) {
      cleanUp();
      return;
    }

    if (formCookieConsent) {
      setShowForm(false);
      return;
    }

    setShowForm(true);
    const existsToken = query?.token && typeof query?.token === 'string';
    if (!existsToken) return;

    const decodedData = atob(query.token as string);
    const parsedData = JSON.parse(decodedData);
    const { id, ...restData } = parsedData;
    const allDataKey = CUSTOM_IDENTIFIER_CONSTANT.map((_, i) => getCookiesHASH(JSON.stringify(restData), i));

    if (!allDataKey.includes(id)) return;

    Cookies.set('form_cookie_consent', JSON.stringify(restData), { domain: cookieDomain });
    setShowForm(false);
    getWBCookiesMail(restData);
    return cleanUp;
  }, [formCookieConsent, node, query, cookieDomain]);

  return { cookieFormTitle, showForm };
}
