import { useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';

import theme from '@mui/theme';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GridContainer from '@atoms/GridContainer/GridContainer';
import BackgroundTransitionBox from '@atoms/BackgroundTransitionBox/BackgroundTransitionBox';
import Row from '@atoms/CustomGrid/Row';

import { emailRegex, nations, phoneRegex } from '@utils/constants/constants';
import { CookieFormModel } from '@model/cookie.model';
import { CookieFormParagraphProps } from './CookieFormParagraph.props';
import { useRecaptchaToken } from '@hooks/useRecaptchaToken';

const useInformativeMaterialsOptions = (t: (k: string) => string) => [
  { code: 'video_di_progetto', name: t('cookie.informativeMaterialsOptions.projectVideo') },
  { code: 'render_dell_opera', name: t('cookie.informativeMaterialsOptions.render') },
  { code: "video_di_progetto_+_render_dell'opera", name: t('cookie.informativeMaterialsOptions.video_render') },
];

const CookieFormParagraph = ({ showForm, title }: CookieFormParagraphProps) => {
  const { t } = useTranslation();

  const informativeMaterialsOptions = useInformativeMaterialsOptions(t);

  const [showMailFeedback, setShowMailFeedback] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const { locale } = useRouter();

  const { getRecaptchaToken } = useRecaptchaToken('cookieForm');

  const cookieForm = useForm<CookieFormModel>({
    defaultValues: {
      nome: '',
      cognome: '',
      email: '',
      nazione: null,
      company: '',
      phone: '',
      materialiDiInteresse: '',
      acceptPrivacyPolicy: 0,
    },
    mode: 'onTouched',
  });

  const { watch, handleSubmit, reset } = cookieForm;

  const formData = watch();

  // const phonePrefix = useMemo(() => {
  //   if (!formData?.nazione) return '';

  //   const selectedNation = countryPhoneCodes.find(({ code }) => code === formData?.nazione.toUpperCase());
  //   if (!selectedNation) return '';

  //   return `${selectedNation?.dial_code} `;
  // }, [formData?.nazione]);

  const isSubmitButtonDisabled = useMemo(() => Object.values(formData).some((value) => !value), [formData]);

  const submitHandler: SubmitHandler<CookieFormModel> = async (formData) => {
    if (formData.acceptPrivacyPolicy === 0) return;

    setSpinner(true);

    try {
      const token = await getRecaptchaToken();
      if (!token) return;

      const data = { ...formData, url, recaptchaToken: token };

      const response = await axios.post(`/api/cookie/?lang=${locale}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Language': locale,
        },
      });

      if (response.status === 200) {
        setShowMailFeedback(true);
      } else {
        console.error('Error sending mail');
      }
    } catch (error) {
      setSpinner(false);
      console.error(error.message);
    }
  };

  return (
    <>
      {!showMailFeedback ? (
        <BackgroundTransitionBox
          colorVariant="light"
          transition="light-to-dark"
          linesDirection="none"
          display={showForm ? 'block' : 'none'}
        >
          {spinner ? (
            <Box textAlign="center" py={160}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <GridContainer>
                <Grid item xs={12} position="relative" textAlign="center" mt={theme.spacing(24)}>
                  {!!title && (
                    <Typography variant="h1" component="h1" color="common.black">
                      {title}
                    </Typography>
                  )}

                  <Typography mt={24} color="common.black" variant="subtitle" component="div">
                    {t('cookie.description')}
                  </Typography>
                </Grid>
              </GridContainer>

              <FormProvider {...cookieForm}>
                <Box
                  id="WB-cookies-form"
                  component="form"
                  mt={theme.spacing(80)}
                  onSubmit={handleSubmit(submitHandler)}
                >
                  <GridContainer>
                    <Row>
                      <Grid item xs={0} lg={2} />
                      <Grid item xs={12} lg={8}>
                        <Box
                          display="grid"
                          rowGap={{ xs: theme.spacing(64), lg: theme.spacing(40) }}
                          columnGap={{ xs: 0, lg: theme.spacing(24) }}
                          gridTemplateColumns={{ xs: '1fr', lg: 'repeat(2, 1fr)' }}
                        >
                          <FormControl>
                            <InputLabel>{t('newsletter.firstName')} *</InputLabel>

                            <Controller
                              name="nome"
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input value={value} autoComplete="given-name" onChange={onChange} onBlur={onBlur} />
                              )}
                            />
                          </FormControl>

                          <FormControl>
                            <InputLabel>{t('newsletter.surname')} *</InputLabel>

                            <Controller
                              name="cognome"
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input value={value} autoComplete="family-name" onChange={onChange} onBlur={onBlur} />
                              )}
                            />
                          </FormControl>

                          <Controller
                            name="email"
                            rules={{
                              pattern: {
                                value: emailRegex,
                                message: t('errorFeedbacks.emailNotValid'),
                              },
                            }}
                            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                              <FormControl error={!!error}>
                                <InputLabel>{t('newsletter.email')} *</InputLabel>
                                <Input
                                  inputRef={ref}
                                  onChange={onChange}
                                  autoComplete="email"
                                  onBlur={onBlur}
                                  value={value}
                                />
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                              </FormControl>
                            )}
                          />

                          <FormControl>
                            <InputLabel>{t('cookie.company')} *</InputLabel>

                            <Controller
                              name="company"
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input autoComplete="organization" value={value} onChange={onChange} onBlur={onBlur} />
                              )}
                            />
                          </FormControl>

                          <Controller
                            name="phone"
                            rules={{
                              pattern: {
                                value: phoneRegex,
                                message: t('errorFeedbacks.phoneNotValid'),
                              },
                            }}
                            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                              <FormControl error={!!error}>
                                <InputLabel>{t('newsletter.phone')} *</InputLabel>
                                <Input ref={ref} value={value} onChange={onChange} onBlur={onBlur} />
                              </FormControl>
                            )}
                          />

                          <Controller
                            name="nazione"
                            render={({ field: { onBlur, onChange, value } }) => {
                              return (
                                <Autocomplete
                                  options={nations}
                                  onBlur={onBlur}
                                  onChange={(ev, value) => onChange(value?.code || '')}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      autoComplete={t('newsletter.country')}
                                      label={`${t('newsletter.country')} *`}
                                      variant="standard"
                                    />
                                  )}
                                />
                              );
                            }}
                          />

                          <Controller
                            name="materialiDiInteresse"
                            render={({ field: { onBlur, onChange, value } }) => {
                              return (
                                <Autocomplete
                                  options={informativeMaterialsOptions}
                                  onBlur={onBlur}
                                  onChange={(ev, value) => onChange(value?.code || '')}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      autoComplete={t('cookie.informativeMaterials')}
                                      label={`${t('cookie.informativeMaterials')} *`}
                                      variant="standard"
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Row>

                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mt={theme.spacing(80)}
                      gap={{ xs: theme.spacing(56), lg: theme.spacing(80) }}
                    >
                      <Box textAlign="center">
                        <Controller
                          name="acceptPrivacyPolicy"
                          render={({ field: { onBlur, onChange, value, ref } }) => (
                            <FormControlLabel
                              color="onLight"
                              control={
                                <Checkbox
                                  onBlur={onBlur}
                                  onChange={(e, checked) => onChange(checked ? new Date().toISOString() : 0)}
                                  value={value}
                                  checked={value !== 0}
                                  inputRef={ref}
                                />
                              }
                              label={
                                <>
                                  <Typography variant="bodyL">
                                    {t('cookie.privacyPolicyText')}{' '}
                                    <Button
                                      disableRipple
                                      component="a"
                                      sx={{ verticalAlign: 'baseline' }}
                                      href="https://www.webuildgroup.com/it/privacycookie"
                                      rel="external"
                                      target="_blank"
                                      variant="textLink"
                                      color="textLink"
                                    >
                                      {t('cookie.privacyPolicy')}
                                    </Button>{' '}
                                    *
                                  </Typography>
                                </>
                              }
                            />
                          )}
                        />
                        <Typography mt={24} color="grey.600" variant="bodyM" component="div">
                          (*) {t('cookie.requiredInfo')}
                        </Typography>
                      </Box>

                      <Button
                        id="WB-cookies-form-submit"
                        disabled={isSubmitButtonDisabled}
                        type="submit"
                        variant="large"
                        color="secondary"
                      >
                        {t('cookie.subscribe')}
                      </Button>
                    </Box>
                  </GridContainer>
                </Box>
              </FormProvider>
            </>
          )}
        </BackgroundTransitionBox>
      ) : (
        <GridContainer>
          <Grid item xs={12} position="relative" textAlign="center" mt={theme.spacing(80)}>
            <Typography variant="h1" component="h1" color="common.black">
              {t('cookie.emailFeedbackTitle')}
            </Typography>

            <Typography mt={24} mb={theme.spacing(80)} color="common.black" variant="subtitle" component="div">
              {t('cookie.emailFeedbackDescription')}
            </Typography>
          </Grid>
        </GridContainer>
      )}
    </>
  );
};

export default CookieFormParagraph;
