import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback } from 'react';

export const useRecaptchaToken = (action: string) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getRecaptchaToken = useCallback(() => {
    if (!executeRecaptcha) {
      console.error('Recaptcha configuration error');
      return;
    }

    return executeRecaptcha(action)
      .then((token) => token)
      .catch((error) => console.error(error.message));
  }, [executeRecaptcha, action]);

  return { getRecaptchaToken };
};
