import axios from 'axios';

export const getWBCookiesMail = async (data) => {
  try {
    const response = await axios.post('/api/wb-cookie-mail', JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (response.status !== 200) {
      console.error('Error sending mail');
    }
  } catch (error) {
    console.error(error.message);
  }
};
