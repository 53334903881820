import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useStyles } from './styles';

const ArticleDisclaimer: React.FC<{ node: any }> = ({ node }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const router = useRouter();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  /** Reset after node-change */
  useEffect(() => {
    setAcceptedTerms(false);
  }, [node]);

  /** Scroll to top after user confirmation */
  useEffect(() => {
    if (!acceptedTerms) return;
    if (!window) return;

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // Fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [acceptedTerms]);

  const goHome = () => {
    router.push('/');
  };
  const accept = () => {
    setAcceptedTerms(true);
  };

  const isArticleWithDisclaimer = 'node--article' === node?.type && node?.field_show_disclaimer;
  const hiddenDisclaimer = !isArticleWithDisclaimer || acceptedTerms;

  if (hiddenDisclaimer) return <></>;

  return (
    <Stack sx={styles.disclaimerContainer}>
      <Box px={[20, 40]} py={40} maxWidth={1160}>
        <Typography fontWeight={500} mb={10} variant="h4">
          {t('ArticleDisclaimer.title')}
        </Typography>

        <Typography mb={16} fontWeight="bold">
          {t('ArticleDisclaimer.subtitle')}
        </Typography>

        {Array(3)
          .fill(1)
          .map((_, i) => (
            <Typography mt={i ? 16 : 0} key={i}>
              {t(`ArticleDisclaimer.initParagraphs.${i}`)}
            </Typography>
          ))}

        {Array(4)
          .fill(1)
          .map((_, i) => (
            <Typography fontWeight="bold" mt={i ? 0 : 16} key={i}>
              {t(`ArticleDisclaimer.materialsParagraphs.${i}`)}
            </Typography>
          ))}

        {Array(5)
          .fill(1)
          .map((_, i) => (
            <Typography mt={i ? 0 : 16} key={i}>
              {t(`ArticleDisclaimer.locationParagraphs.${i}`)}
            </Typography>
          ))}

        {Array(2)
          .fill(1)
          .map((_, i) => (
            <Typography mt={16} key={i}>
              {t(`ArticleDisclaimer.acknowledgeParagraphs.${i}`)}
            </Typography>
          ))}

        <Typography mt={16}>
          {t('ArticleDisclaimer.disagreeParagraph.first')}
          <strong>{t('ArticleDisclaimer.disagreeParagraph.iDisagree')}</strong>
          {t('ArticleDisclaimer.disagreeParagraph.second')}
        </Typography>

        <Typography mt={16}>{t(`ArticleDisclaimer.accessingParagraph`)}</Typography>

        <Stack paddingTop={16} width="100%" direction="row" alignItems="center" justifyContent="center">
          <Stack width="50%" direction="row" alignItems="center" justifyContent="center">
            <Button onClick={accept} sx={styles.button}>
              {t('ArticleDisclaimer.accept')}
            </Button>
          </Stack>

          <Stack width="50%" direction="row" alignItems="center" justifyContent="center">
            <Button onClick={goHome} sx={styles.button}>
              {t('ArticleDisclaimer.refuse')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ArticleDisclaimer;
