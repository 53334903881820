import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import voca from 'voca';

export const useBreadcrumbSegments = (node) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!node?.path?.alias) return [];

    const segments = node.path.alias.split('/');

    segments[0] = `/${node.path.langcode}`;

    return segments.reduce((acc, segment, index) => {
      segment = segment == 'search-data' ? 'search' : segment;
      if (index === 0)
        return [
          {
            url: segment,
            label: voca.titleCase(t('homepage')),
            isActive: `/${node.path.langcode}/${node.path.alias}` === segment,
          },
        ];

      const url = `${acc[index - 1].url}/${segment}`;

      return [
        ...acc,
        {
          url,
          label: voca.titleCase(segment),
          isActive: segment == 'search' ? true : `/${node.path.langcode}${node.path.alias}` === url,
        },
      ];
    }, []);
  }, [node]);
};
