import { useCallback, useMemo } from 'react';
import { Theme } from '@mui/material';

export const useStyles = () => {
  const disclaimerContainer = useMemo(
    () => ({
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      '& ~ *': { display: 'none !important' },
    }),
    []
  );

  const button = useCallback((theme: Theme) => ({ paddingX: 30, [theme.breakpoints.up('sm')]: { width: 'auto' } }), []);

  return { disclaimerContainer, button };
};
