import { AppBreakpoint } from '@model/types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { themeOptions } from '@mui/theme';

export const useBreakpoint = (breakpoint: AppBreakpoint) => {
  const isLarger = useMediaQuery(`(min-width: ${themeOptions.breakpoints.values[breakpoint]}px)`, {
    defaultMatches: true,
  });

  const isSmaller = useMediaQuery(`(max-width: ${themeOptions.breakpoints.values[breakpoint]}px`, {
    defaultMatches: true,
  });

  return {
    isLarger,
    isSmaller,
  };
};
