import { BackgroundTransitionBoxProps } from '@atoms/BackgroundTransitionBox/BackgroundTransitionBox.props';
import Box from '@mui/material/Box';
import { PropsWithChildren, useCallback } from 'react';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import { useColors } from '@hooks/useColors';

const GridMask = dynamic(() => import('@atoms/GridMask/GridMask'));

const BackgroundTransitionBox = (props: PropsWithChildren<BackgroundTransitionBoxProps>) => {
  const {
    transition,
    colorVariant,
    isGridMaskDisabled,
    isBackgroundColorDisabled = false,
    isTransitionBoxDisabled,
    linesDirection,
    children,
    ...otherProps
  } = props;

  const { backgroundColor } = useColors(colorVariant);

  const getBgColor = useCallback(
    (backgroundColor) => {
      return isBackgroundColorDisabled ? 'transparent' : backgroundColor;
    },
    [isBackgroundColorDisabled]
  );

  if (isTransitionBoxDisabled) {
    return (
      <Box {...otherProps} bgcolor={getBgColor(backgroundColor)}>
        {children}
      </Box>
    );
  }

  if (!transition) {
    return (
      <Box {...otherProps} position="relative" bgcolor={getBgColor(backgroundColor)}>
        {!isGridMaskDisabled && <GridMask colorVariant={colorVariant} direction={linesDirection} />}
        {children}
      </Box>
    );
  }

  if (transition === 'dark-to-light') {
    return (
      <Box bgcolor={getBgColor('common.white')} pb={90}>
        <Box
          boxShadow={theme.boxShadows.backgroundTransitionDarkToLight}
          bgcolor="common.black"
          position="relative"
          {...otherProps}
          borderRadius={theme.spacing(0, 0, 40, 40)}
        >
          {!isGridMaskDisabled && <GridMask direction={linesDirection} colorVariant="dark" />}
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box {...otherProps}>{children}</Box>
      <Box
        boxShadow={theme.boxShadows.backgroundTransitionLightToDark}
        bgcolor={getBgColor('common.black')}
        position="relative"
        height={theme.spacing(80)}
        mt={90}
        borderRadius={theme.spacing(40, 40, 0, 0)}
      >
        {!isGridMaskDisabled && <GridMask colorVariant="dark" direction={linesDirection} />}
      </Box>
    </>
  );
};

export default BackgroundTransitionBox;
