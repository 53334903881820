export const checkContentAndTaxonomy = (node, type = 'discovery', tax = '207') => {
  // TODO: fare chiamata a taxonomy term per reperire name
  if (node.type === 'node--' + type) {
    const hasVideoTaxonomy = node.field_category?.resourceIdObjMeta?.drupal_internal__target_id == tax;
    if (hasVideoTaxonomy) {
      return true;
    }
  }
  return false;
};
