export const defaultMenus = {
  footerMenuColOne: [],
  footerMenuColTwo: [],
  copyrightMenu: [],
  blockFooterTop: [],
  blockFooterMiddle: [{ body: null }, { body: null }, { body: null }, { body: null }, { body: null }],
  blockFooterBottom: [],
  blockSearchConfig: [],
  mainMenu: [],
  mainMenuSecondary: [],
};
