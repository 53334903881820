import { GetStaticPaths, GetStaticProps, GetStaticPropsContext, GetStaticPropsResult, PreviewData } from 'next';
import dynamic from 'next/dynamic';
import { drupal } from '@utils/constants/drupal';
import { Layout } from '@organisms/Layout/Layout';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import MetaTags from '@atoms/MetaTags';
import { getMenus } from '@utils/functions/get-menus';
import { DrupalNode, DrupalTranslatedPath } from 'next-drupal';
import { defaultMenus } from '@utils/constants/default-menus';
import getDrupalView from '@utils/functions/getDrupalView';
import Breadcrumb from '@molecules/Breadcrumb/Breadcrumb';
import Box from '@mui/material/Box';
import { getColorVariant } from '@utils/functions/isValidColor';
import { paragraphsType } from '@utils/constants/paragraph-type';
import ArticleDisclaimer from '@components/organisms/ArticleDisclaimer/ArticleDisclaimer';
import CookieFormParagraph from '@components/organisms/CookieFormParagraph/CookieFormParagraph';
import { isUncorrectPath, isUncorrectType } from '@utils/functions/isUncorrectPath';
import useCookieForm from '@hooks/useCookieForm';
import { checkContentAndTaxonomy } from '@utils/functions/checkContentAndTaxonomy';
import { saveNidInCookie } from '@utils/functions/saveNidInCookie';

// import DynamicBlock from '@molecules/DynamicBlock/DynamicBlock';
// import { getParagraphsType } from '@utils/functions/getParagraphType';

const DynamicBlock = dynamic(() => import('@molecules/DynamicBlock/DynamicBlock'));

const fs = require('fs');

export interface IndexPageProps {
  node;
  langData;
  menus;
}

const RESOURCE_TYPES = [
  'node--article',
  'node--discovery',
  'node--esg',
  'node--page',
  'node--person',
  'node--podcast_episode',
  'node--project',
  'node--press_release',
  'node--event',
];

const DynamicPage = ({ node, menus, langData }: IndexPageProps) => {
  const { cookieFormTitle, showForm } = useCookieForm(node as DrupalNode);

  if (!node?.field_paragraphs) return null;

  const colorVariant = getColorVariant(
    node.field_paragraphs[0]?.behavior_settings?.style_options?.color_scheme?.css_class || 'light'
  );

  // save cookie if content type is "discovery" and taxonomy type "video" "207"
  if (checkContentAndTaxonomy(node, 'discovery', '207')) {
    saveNidInCookie(node.drupal_internal__nid);
  }

  return (
    <Layout menus={menus || defaultMenus} node={node} langData={langData}>
      <Box display={showForm ? 'none' : 'block'}>
        {!+process.env.NEXT_PUBLIC_USE_MOCK_API && (
          <>
            <MetaTags node={node} langData={langData} />
            <Box
              display="flex"
              bgcolor={colorVariant === 'light' ? 'common.white' : 'common.black'}
              justifyContent="center"
            >
              <Breadcrumb colorVariant={colorVariant} node={node} />
            </Box>
          </>
        )}
        <ArticleDisclaimer node={node} />
        {RESOURCE_TYPES.includes(node.type) && (
          <>
            {node.field_paragraphs.map((field, index) => (
              <DynamicBlock key={`${field.type}_${index}`} type={field.type} fieldData={field} node={node} />
            ))}
          </>
        )}
      </Box>

      <CookieFormParagraph showForm={showForm} title={cookieFormTitle} />
    </Layout>
  );
};

export const getStaticPaths: GetStaticPaths = async (context) => {
  if (+process.env.NEXT_PUBLIC_USE_MOCK_API) {
    return {
      paths: [
        {
          locale: 'it',
          params: {
            slug: [],
          },
        },
      ],
      fallback: false,
    };
  }

  return {
    fallback: 'blocking',
    paths: await drupal.getStaticPathsFromContext(
      [
        // 'node--page',
        // 'node--article',
        // 'node--discovery',
        // 'node--esg',
        // 'node--person',
        // 'node--podcast_episode',
        // 'node--project',
      ],
      context
    ),
  };
};

// export const getServerSideProps: GetServerSideProps = async (
//   context
// ): Promise<GetServerSidePropsResult<IndexPageProps>> => {
export const getStaticProps: GetStaticProps = async (context): Promise<GetStaticPropsResult<IndexPageProps>> => {
  if (+process.env.NEXT_PUBLIC_USE_MOCK_API) {
    const filePath =
      process.env.NODE_ENV === 'development'
        ? `${process.cwd()}/public/data/${process.env.NEXT_PUBLIC_MOCK_JSON_URL}`
        : `${process.cwd()}/public/data/homepage-paragraphs.json`;

    const node = await fs.readFileSync(filePath);

    return {
      props: {
        ...(await serverSideTranslations(context.locale, ['common'])),
        node: JSON.parse(node),
        langData: [],
        menus: defaultMenus,
      },
    };
  }

  const isMultiLang = Boolean(+process.env.NEXT_PUBLIC_MULTILANG);

  // Obtaining menus from configured Multisite context.
  // -------------------------------------------------------
  const menuOptions = {
    main: process.env.DRUPAL_MULTISITE_MENU_MAIN,
    ecosystem: process.env.DRUPAL_MULTISITE_MENU_ECOSYSTEM,
    footer: process.env.DRUPAL_MULTISITE_MENU_FOOTER_ONE,
    footerTwo: process.env.DRUPAL_MULTISITE_MENU_FOOTER_TWO,
    copyright: process.env.DRUPAL_MULTISITE_MENU_COPYRIGHT,
    socials: process.env.DRUPAL_MULTISITE_MENU_SOCIALS,
  };

  const menus = await getMenus(context, menuOptions);

  const path = await drupal.translatePathFromContext(context);

  const isPressReleaseFile = path?.entity?.bundle === 'press_release';

  if (!path || isUncorrectPath(context, path) || isUncorrectType(path?.entity?.type) || isPressReleaseFile) {
    return {
      notFound: true,
      revalidate: 120,
    };
  }

  //create params for paraghraph
  // const includeTypes = getParagraphsType(path);

  const node = await drupal.getResourceFromContext<DrupalNode>(path, context, {
    params: {
      include: paragraphsType.join(','),
      // include: includeTypes,
    },
  });

  // At this point, we know the path exists and it points to a resource.
  // If we receive an error, it means something went wrong on Drupal.
  // We throw an error to tell revalidation to skip this for now.
  // Revalidation can try again on next request.
  if (!node) {
    throw new Error(`Failed to fetch resource: ${path.jsonapi.individual}`);
  }

  // check multisite tax
  const isSiteContext = node.field_context?.some((contextItem) => {
    return contextItem?.resourceIdObjMeta?.drupal_internal__target_id == process.env.NEXT_PUBLIC_MULTISITE_CONTEXT_TAX;
  });

  if (!isSiteContext) {
    return {
      notFound: true,
      revalidate: 120,
    };
  }

  node?.field_paragraphs.forEach((paragraph, i) => {
    if (paragraph.field_reusable_paragraph) {
      node.field_paragraphs[i] = paragraph.field_reusable_paragraph?.paragraphs;
    }
  });

  for (let paragraph of node?.field_paragraphs) {
    // add view
    if (paragraph.field_view) {
      paragraph.view = await getDrupalView({
        field_view: paragraph.field_view,
        context,
      });
    }

    if (!paragraph.field_items) continue;

    for (const item of paragraph.field_items) {
      if (
        !item.field_view?.resourceIdObjMeta?.drupal_internal__target_id ||
        !item.field_view?.resourceIdObjMeta?.display_id
      ) {
        continue;
      }

      item.view = await getDrupalView({
        field_view: item.field_view,
        context,
      });
    }
  }

  const langPromises = isMultiLang
    ? context.locales.map((lang: string) =>
        drupal.getResource(node.type, node.id, {
          locale: lang,
          defaultLocale: 'it',
        })
      )
    : [];
  const langPromisesData = await Promise.all(langPromises);
  const langData = langPromisesData?.reduce((acc, result, i) => {
    const isWrongLang = context.locales.at(i) !== result?.path?.langcode;
    if (isWrongLang) return acc;

    return [
      ...acc,
      {
        lang: result?.path?.langcode,
        path:
          result?.path?.alias === process.env.DRUPAL_MULTISITE_FRONT_PAGE
            ? `${result?.path?.langcode}`
            : `${result?.path?.langcode}/${result?.path?.alias}`,
      },
    ];
  }, []);

  // If we're not in preview mode and the resource is not published,
  // Return page not found.
  if (!context.preview && node?.status === false) {
    return {
      notFound: true,
      revalidate: 120,
    };
  }

  // sesrversideprops
  // const cacheControl = 'public, s-maxage= 345600, stale-while-revalidate=59';
  // context.res.setHeader('Cache-Control', cacheControl);

  return {
    props: {
      ...(await serverSideTranslations(context.locale, ['common'])),
      node,
      langData,
      menus,
    },
    revalidate: 120, // 2000000000,
  };
};

export default DynamicPage;
