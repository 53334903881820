import { CUSTOM_IDENTIFIER_CONSTANT } from '@utils/constants/constants';

export async function getCookiesHASH(data: string, index: number): Promise<string> {
  const encoder = new TextEncoder();
  const randomKey: string = CUSTOM_IDENTIFIER_CONSTANT[index];
  const dataKey = `${data}||${randomKey}`;
  const hashBuffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', encoder.encode(dataKey));
  const hashArray: number[] = Array.from(new Uint8Array(hashBuffer));
  const hashHex: string = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
